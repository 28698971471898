<template>
  <div>
    <v-card  class="pa-0 ma-0" v-if="listOfComments.length > 0" flat>
      <template v-for="(comment, index) in listOfComments">
        <div :key="index">
          <v-card class="mb-3">
            <v-row class="mx-0 pa-1">
              <v-flex xs2>
                <v-avatar color="primary" class="pa-0" size="32">
                  <span class="mx-auto white--text">
                    <b>{{ comment.user_lastname ? `${comment.user_firstname.charAt(0).toUpperCase()}${comment.user_lastname.charAt(0).toUpperCase()}`  : comment.user_firstname.charAt(0).toUpperCase() }}</b>
                  </span>
                </v-avatar>
              </v-flex>
              <v-flex xs8 class="mt-1">
                <span class="font-weight-medium"> {{comment.user_firstname}} {{comment.user_lastname}} </span>
                <p class="font-weight-regular"> {{ $formatter.formatDate(comment.modified_at, 'DD.MM.YYYYTHH.mm.ss', `${userDetails.dateformat} HH:mm`) }} </p>
              </v-flex>
              <v-flex xs2 v-if="!comment.showEdit">
                <v-layout row wrap class="mx-0 mt-2 mr-1" justify-end>
                  <v-icon @click="showEditHandler(index)" v-if="userId === comment.updated_by" small>mdi-pencil</v-icon>
                </v-layout>
              </v-flex>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-0">
              <v-col cols="12" lg="10" class="px-1" v-if="!comment.showEdit">
                <p v-html="comment.comment"></p>
              </v-col>
              <v-col cols="12" lg="11" v-else>
                <div id="app-editor-comments">
                  <ckeditor :editor="editor" v-model="comment.comment" :config="configuration" id="app-comments-section" :key="reRender"></ckeditor>
                  <!-- <app-editor :editor="editor" :config="configuration" v-model="comment.comment" id="app-comments-section"></app-editor> -->
                </div>
                <v-flex class="text-right">
                <v-btn-toggle v-model="toggle_exclusive">
                  <v-btn small @click="updateComments(index)">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                  <v-btn small @click="listOfComments[index].showEdit = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </template>
    </v-card>
    <v-alert prominent text v-else icon="mdi-information" :color="!$vuetify.theme.dark && systemDetails && systemDetails.themecolor ? systemDetails.themecolor : ''" class="mt-2" >
      <v-row align="center">
        <v-col class="grow">{{ $t('noComments') }}</v-col>
      </v-row>
    </v-alert>
    <div class="pt-3">
      <div v-if="showCommentBox" class="commentBox  font-weight-regular text--primary">
        <ckeditor :editor="editor" v-model="modelObj.comment" :config="configuration"></ckeditor>
        <!-- <app-editor :editor="editor" :config="configuration" v-model="modelObj.comment"></app-editor> -->
          <v-flex class="text-right">
            <v-btn-toggle v-model="toggle_exclusive" class="justify-right">
              <v-btn small @click="saveComments()">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn small @click="showCommentBox = false, modelObj.comment = ''">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-btn-toggle>
        </v-flex>
      </div>
      <v-btn block :color="!$vuetify.theme.dark && systemDetails && systemDetails.themecolor ? systemDetails.themecolor : ''" v-else outlined @click="showCommentBox = true">{{$t('addComments') }}</v-btn>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import Editor from '@/ckeditor'
export default {
  props: ['ticketNumber', 'assignedto', 'listOfComments'],
  data () {
    return {
      ticketId: this.$route.params.ticket_id,
      editor: Editor,
      toggle_exclusive: 2,
      showCommentBox: false,
      userId: this.$store.state.auth.userDetails._id,
      modelObj: {
        ticket_id: this.$route.params.ticket_id,
        comment: ''
      },
      configuration: {
        height: '250px',
        sharedSpaces: { 
          top: 'toolbarLocation' 
        },
        toolbar: {
          items: [ 'bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote', 'undo', 'redo']
        },
        mention: {},
      },
      listOfUsers: [],
      reRender: 0
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getsignalRConnection', 'getListOfGroups', 'userDetails', 'systemDetails'])
  },
  mounted () {
    let users = this.$formatter.cloneVariable(this.getUsers)
    users.forEach(element => {
      let  id = element._id
      element.userId = id
      element.id = `@${element.name}`
      element.link = 'https://ckeditor.com/docs/ckeditor5/latest/examples/framework/chat-with-mentions.html'
    })
    this.listOfUsers = users
    this.configuration.mention = {
      feeds: [{
        marker: '@',
        feed: this.listOfUsers,
        itemRenderer: this.customItemRenderer
      }]
    }
  },
  methods: {
    customItemRenderer( item ) {
      const itemElement = document.createElement( 'span' )
      itemElement.classList.add( 'custom-item' )
      itemElement.id = `mention-list-item-id-${ item.userId }`
      itemElement.textContent = `${ item.name } `
      const usernameElement = document.createElement( 'span' )
      // usernameElement.classList.add( 'custom-item-username' )
      // usernameElement.textContent = item.id
      // itemElement.appendChild( usernameElement )
      return itemElement
    },
    showEditHandler(index) {
      this.listOfComments[index].showEdit = true
      this.$set(this.listOfComments, index, this.listOfComments[index])
    },
    saveComments () {
      let mentionedIds = []
      let elements = document.querySelectorAll('.commentBox .mention')
      elements.forEach(element => {
        let id = element.attributes[2].value
        mentionedIds.push(parseInt(id))
      })
      let model = this.$formatter.cloneVariable(this.modelObj)
      this.$api.execute('post', 'ticketcomments', model).then(response => {
        if (mentionedIds.length > 0) {
          let  msg = `You are mentioned in ${this.ticketNumber} by ${this.$store.state.auth.userDetails.name}`
          this.getsignalRConnection.invoke('SendNotification', mentionedIds, msg, this.ticketId)
        }
        if (this.assignedto) {
          let assignedTo = this.$formatter.cloneVariable(this.assignedto)
          assignedTo = assignedTo.split('_')
          if (assignedTo[0] === 'group') {
            let item = this.getListOfGroups.find(x => x.id == assignedTo[1])
            let usersarray = []
            // let query = { filter: `groupid eq ${assignedTo[1]} and isactive eq 1` }
            // let  msg = `${this.$store.state.auth.userDetails.name} added comments in ${this.ticketNumber}`
            //   this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
            //   usersarray = result.data.map(x => x.user_id)
            // }).finally(() => {
            //   this.getsignalRConnection.invoke('SendNotification', usersarray, msg, this.ticketId)
            // })
          } else {
            if (this.$store.state.auth.userDetails._id != assignedTo[1]) {
               let  msg = `${this.$store.state.auth.userDetails.name} added comments in ${this.ticketNumber}`
              this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg, this.ticketId)
            }
          }
        }
        this.$root.$emit('loadComments', this.ticketId)
        this.modelObj.comment = ''
        this.showCommentBox = false
      })
    },
    updateComments (index) {
      let model = this.$formatter.cloneVariable(this.listOfComments[index])
      this.$api.execute('put', `ticketcomments/${model._id}`, model).then(response => {
        this.$root.$emit('loadComments', this.ticketId)
        this.listOfComments[index].showEdit = false
      })
    }
  }
}
</script>
<style>
.custom-item  {
  display: block;
}
</style>